import React, { memo, ReactNode } from 'react';
import './VisitorJumbotron.scss';

interface Props {
  title: ReactNode;
  description: ReactNode;
  smallerTitle?: boolean;
}

export const VisitorJumbotron = memo<Props>(({ title, description, smallerTitle }) => {
  return (
    <div className="VisitorJumbotron">
      <h1 className={`VisitorJumbotron-title ${smallerTitle ? 'mod-smallerTitle' : ''}`}>{title}</h1>
      <p className="VisitorJumbotron-description">{description}</p>
    </div>
  );
});

VisitorJumbotron.displayName = 'VisitorJumbotron';

export default VisitorJumbotron;
