import ActionCompleteLayout from 'components/ActionCompleteLayout/ActionCompleteLayout';
import i18nNamespaces from 'i18n/i18nNamespaces';
import { useTranslation } from 'react-i18next';

const PaymentComplete: React.FC = () => {
  const { t } = useTranslation(i18nNamespaces.PAYMENT_COMPLETE);
  return <ActionCompleteLayout title={t('title')} description={t('description')} ctaLabel={t('cta')} to="/" />;
};

PaymentComplete.displayName = 'PaymentComplete';

export default PaymentComplete;
