import React, { memo, ReactNode } from 'react';
import Modal, { Styles, Props as ModalProps } from 'react-modal';
import './AlertModal.scss';
import CTAButton from '../CTAButton/CTAButton';

interface Props {
  isOpen: boolean;
  onRequestClose?: ModalProps['onRequestClose'];
  description: string;
  onConfirm: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
  errorMessage?: string;
}

export const AlertModal = memo<Props>(
  ({
    isOpen,
    onRequestClose,
    description,
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    onConfirm,
    onCancel,
    confirmDisabled,
    cancelDisabled,
    errorMessage,
  }) => {
    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyle}>
        <div className="AlertModal">
          <div className="AlertModal-content">
            <p className="AlertModal-content-description">{description}</p>
            {!!errorMessage && <p className="AlertModal-content-errorMessage">{errorMessage}</p>}
          </div>
          <div className="AlertModal-buttonsRow">
            {onCancel && <CTAButton text={cancelLabel} onClick={onCancel} disabled={cancelDisabled} invert />}
            <CTAButton text={confirmLabel} onClick={onConfirm} disabled={confirmDisabled} />
          </div>
        </div>
      </Modal>
    );
  },
);

AlertModal.displayName = 'AlertModal';

export default AlertModal;

const modalStyle: Styles = {
  content: { height: 250, borderRadius: '10px', margin: 'auto', padding: '1.5rem' },
  overlay: { zIndex: 9999, backgroundColor: 'rgba(0, 0, 0, 0.5)' },
};
