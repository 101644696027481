import React, { memo, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import './LangSwitchButton.scss';

type Props = Partial<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>;

export const LangSwitchButton = memo<Props>(({ className = '', type = 'button', ...restProps }) => {
  const { t } = useTranslation(i18nNamespaces.APP);
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={`LangSwitchButton ${className}`} {...restProps}>
      <span className="LangSwitchButton-title">{t('lang_short')}</span>
      <span className="LangSwitchButton-icon" />
    </button>
  );
});

LangSwitchButton.displayName = 'LangSwitchButton';

export default LangSwitchButton;
