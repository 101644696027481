import React, { memo } from 'react';
import Config from '../../Config';
import './ApplicationVersion.scss';

interface Props {
  opacity?: string;
  position?: 'right' | 'left';
  absolute?: true;
  className?: string;
}

export const ApplicationVersion = memo<Props>(({ absolute, opacity, position, className }) => {
  return (
    <div
      style={{
        position: absolute ? 'absolute' : 'unset',
        opacity,
        left: position === 'right' ? undefined : '1.5rem',
        right: position === 'right' ? '1.5rem' : undefined,
      }}
      className={`ApplicationVersion ${className || ''}`}
    >
      <span>
        {`v${Config.version} ${Config.environment !== 'prod' ? `(${Config.environment?.toUpperCase()})` : ''}`}
      </span>
    </div>
  );
});

ApplicationVersion.displayName = 'ApplicationVersion';

export default ApplicationVersion;
