import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

import i18nNamespaces from 'i18n/i18nNamespaces';
import clearAllCaches from 'utils/clearAllCaches';
import Config from '../../Config';
import AlertModal from '../../components/AlertModal/AlertModal';

const AppVersionCheckPrompt: FC = () => {
  const { t } = useTranslation(i18nNamespaces.APP);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isClearingCache, setIsClearingCache] = useState(false);

  const handleAlertCancel = useCallback(() => {
    setIsAlertOpen(false);
  }, []);

  const handleAlertConfirm = useCallback(async () => {
    setIsAlertOpen(false);
    setIsClearingCache(true);
    try {
      await clearAllCaches();
      window.location.reload();
    } catch (error) {
      // @TODO: Prompt error
    } finally {
      setIsClearingCache(false);
      setIsAlertOpen(false);
    }
  }, []);

  useEffect(() => {
    const checkVersion = async () => {
      const metadata = await fetch(`/metadata.json?q=${new Date().getTime()}`).then((res) => res.json());
      if (!metadata) return;
      if (metadata.version !== Config.version) setIsAlertOpen(true);
    };
    checkVersion();
  }, []);

  return (
    <AlertModal
      isOpen={isAlertOpen}
      description={t('askForRefreshApp')}
      onConfirm={handleAlertConfirm}
      onCancel={handleAlertCancel}
      cancelDisabled={isClearingCache}
      confirmDisabled={isClearingCache}
      confirmLabel={isClearingCache ? <ClipLoader color="#fff" /> : undefined}
    />
  );
};
AppVersionCheckPrompt.displayName = 'AppVersionCheckPrompt';

export default AppVersionCheckPrompt;
