import React, { forwardRef, HTMLProps, memo, ReactElement, ReactNode, useCallback } from 'react';
import './InputTextField.scss';

export type InputStatus = 'active' | 'error' | 'inactive' | 'disabled';

export interface InputTextFieldProps extends Omit<HTMLProps<HTMLInputElement | HTMLTextAreaElement>, 'label'> {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  bottomClassName?: string;
  label?: ReactNode;
  status?: InputStatus;
  name: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'textarea';
  placeholder?: string;
  defaultValue?: string;
  bottomLabel?: string;
  forceHideLabel?: boolean;
  forceHideBottomLabel?: boolean;
  value?: string | number;
  disablePaste?: boolean;
  disableAutoComplete?: true;
  elementAfter?: ReactElement;
}

export const InputTextField = forwardRef(
  (
    {
      className = '',
      labelClassName = '',
      inputClassName = '',
      bottomClassName = '',
      label,
      name,
      status = 'active',
      type = 'text',
      defaultValue,
      value,
      bottomLabel,
      forceHideLabel,
      forceHideBottomLabel,
      disablePaste,
      disableAutoComplete,
      elementAfter,
      onPaste,
      // To fix `onChange` undefined while `value` defined error
      onChange = () => null,
      ...restProps
    }: InputTextFieldProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
  ) => {
    const inputElementProps = {
      ...restProps,
      autoComplete: disableAutoComplete ? 'new-password' : 'on',
      className: `${
        status === 'disabled'
          ? 'InputTextField-input-disabled'
          : status === 'error'
          ? 'InputTextField-input-error'
          : 'InputTextField-input'
      } ${inputClassName}`,
      name,
      ref,
      value: value || defaultValue,
      onChange,
      readOnly: status === 'disabled',
      onPaste: useCallback(
        (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (disablePaste) e.preventDefault();
          if (onPaste) onPaste(e);
        },
        [disablePaste, onPaste],
      ),
    };

    return (
      <div data-testid="InputTextField" className={`InputTextField ${className}`}>
        {!forceHideLabel && <div className={`InputTextField-input-label  ${labelClassName}`}>{label}</div>}
        {type === 'textarea' ? <textarea {...inputElementProps} /> : <input {...inputElementProps} type={type} />}
        {!forceHideBottomLabel && (
          <div
            className={`${
              status === 'error' ? 'InputTextField-input-label-bottom-error' : 'InputTextField-input-label-bottom'
            } ${bottomClassName}`}
          >
            {bottomLabel}
          </div>
        )}
        {elementAfter}
      </div>
    );
  },
);

InputTextField.displayName = 'InputTextField';

export default memo(InputTextField);
