import { AxiosError } from 'axios';
import i18n from 'i18n';
import i18nNamespaces from 'i18n/i18nNamespaces';

const getAPIErrorI18nKey = (
  err: AxiosError<any, any>,
  namespace: string,
  defaultNamespace: string = i18nNamespaces.APP,
): string => {
  const errKey = `error_${err?.response?.data?.error ?? ''}`;
  if (i18n.exists(`${namespace}:${errKey}`)) {
    return errKey;
  }
  return `${defaultNamespace}:systemError`;
};

export default getAPIErrorI18nKey;
