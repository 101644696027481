import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ICalendarLink from 'react-icalendar-link';
import './CTAButton.scss';
import { ICSEvent } from '../../models/ICSEvent';

interface Props {
  text: ReactNode;
  to?: string;
  disabled?: boolean;
  fill?: true;
  invert?: true;
  iconSrc?: string;
  className?: string;
  type?: 'button' | 'submit' | 'ics';
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  noBorder?: boolean;
  iconPosition?: 'left' | 'right';
  calendarEvent?: ICSEvent;
  filename?: string;
}

export const CTAButton = React.memo<Props>(
  ({
    type,
    text,
    to,
    disabled,
    fill,
    invert,
    iconSrc,
    className,
    onClick,
    noBorder,
    iconPosition,
    calendarEvent,
    filename,
  }) => {
    if (to) {
      return (
        <Link
          onClick={onClick}
          className={`cta-button ${fill ? 'mod-fill' : ''} ${invert ? 'mod-invert' : ''}`}
          to={to}
        >
          {text}
        </Link>
      );
    }

    if (type === 'ics') {
      return (
        // @ts-expect-error: @TODO: fix error
        <ICalendarLink
          className={`cta-button ${fill ? 'mod-fill' : ''}  ${invert ? 'mod-invert' : ''} ${className || ''} ${
            disabled ? 'mod-disabled' : ''
          } ${noBorder ? 'mod-no-border' : ''}`}
          filename={filename}
          event={calendarEvent}
        >
          {text}
        </ICalendarLink>
      );
    }
    return (
      <button
        disabled={disabled}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={onClick}
        className={`cta-button ${fill ? 'mod-fill' : ''}  ${invert ? 'mod-invert' : ''} ${className || ''} ${
          disabled ? 'mod-disabled' : ''
        } ${noBorder ? 'mod-no-border' : ''}`}
      >
        {iconPosition === 'left' && iconSrc && <img alt="" className="mod-left" src={iconSrc} />}
        <span>{text}</span>
        {iconPosition === 'right' && iconSrc && <img alt="" className="mod-right" src={iconSrc} />}
      </button>
    );
  },
);

CTAButton.displayName = 'CTAButton';

export default CTAButton;
