/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import CreditCardPaymentForm from 'pages/CreditCardPaymentForm/CreditCardPaymentForm';
import PaymentComplete from 'pages/PaymentComplete/PaymentComplete';

import { ScrollToTop } from './components';

const MainRouter = (): JSX.Element => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Route exact path="/payment/credit-card/success" component={PaymentComplete} />
      <Route exact path="/payment/credit-card" component={CreditCardPaymentForm} />
      <Route exact path="/" component={() => <Redirect to="/payment/credit-card" />} />
    </Switch>
  </Router>
);

export default MainRouter;
