import React, { memo, ReactElement, useCallback, useState } from 'react';

import ApplicationVersion from 'components/ApplicationVersion/ApplicationVersion';
import LangSwitchButton from '../../components/LangSwitchButton/LangSwitchButton';
import ModalButtonList, { ButtonList } from '../../components/ModalButtonList/ModalButtonList';
import i18n from '../../i18n';
import i18nNamespaces from '../../i18n/i18nNamespaces';
import languages from '../../i18n/languages';
import './VisitorLayout.scss';
// @TODO: Replace this
import paymentBg from '../../assets/images/payment-bg.jpeg';

export interface VisitorFormLayoutChildrenProps {
  formClassName: string;
  formContainerClassName: string;
  formErrorClassName: string;
  formRemarkClassName: string;
  formButtonsContainerClassName: string;
  formHintButtonClassName: string;
}

interface Props {
  children: (childrenProps: VisitorFormLayoutChildrenProps) => ReactElement;
  hasError: boolean;
  className?: string;
}

export const VisitorLayout = memo<Props>(({ children, hasError, className }) => {
  const [isLangSwitchOpen, setIsLangSwitchOpen] = useState(false);

  const langList: ButtonList[] = Object.values(languages).map((langCode) => {
    const tLng = i18n.getFixedT(langCode, i18nNamespaces.APP);
    return {
      title: tLng('lang'),
      onClick: () => i18n.changeLanguage(langCode),
      active: langCode === i18n.language || undefined,
    };
  });
  return (
    <div className={`VisitorLayout ${className || ''}`}>
      <div className="VisitorLayout-container">
        <div className="VisitorLayout-header">
          <LangSwitchButton onClick={useCallback(() => setIsLangSwitchOpen(true), [])} />
        </div>
        {children({
          formClassName: 'VisitorLayout-form',
          formContainerClassName: 'VisitorLayout-form-container',
          formErrorClassName: `VisitorLayout-form-error ${hasError ? 'mod-show' : ''}`,
          formRemarkClassName: 'VisitorLayout-form-remark',
          formButtonsContainerClassName: 'VisitorLayout-form-buttons',
          formHintButtonClassName: 'VisitorLayout-hint-button',
        })}
        <ModalButtonList
          list={langList}
          isOpen={isLangSwitchOpen}
          onRequestClose={useCallback(() => setIsLangSwitchOpen(false), [])}
        />
        <ApplicationVersion />
      </div>
      <img className="VisitorLayout-img" alt="us-flag" src={paymentBg} />
    </div>
  );
});

VisitorLayout.displayName = 'VisitorLayout';

export default VisitorLayout;
