import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Validate } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import parseHTML from 'html-react-parser';
import formatCreditCardNum from 'simply-utils/number/formatCreditCardNum';
import formatCardExp from 'simply-utils/number/formatCardExp';
import formatCreditCardSecurityCode from 'simply-utils/number/formatCreditCardSecurityCode';
import isValidCreditCardNum from 'simply-utils/validators/isValidCreditCardNum';
import isValidCreditCardSecurityCode from 'simply-utils/validators/isValidCreditCardSecurityCode';
import zeroPadding from 'simply-utils/number/zeroPadding';
import creditCardType from 'credit-card-type';

import amexLogo from 'assets/images/amex-logo.png';
import dinersClubLogo from 'assets/images/diners-club-logo.png';
import discoverLogo from 'assets/images/discover-logo.png';
import jcbLogo from 'assets/images/jcb-logo.png';
import mastercardLogo from 'assets/images/mastercard-logo.png';
import unionpayLogo from 'assets/images/unionpay-logo.png';
import visaLogo from 'assets/images/visa-logo.png';

import InputTextField from 'components/InputTextField/InputTextField';
import VisitorLayout from 'layout/VisitorLayout/VisitorLayout';
import VisitorJumbotron from 'components/VisitorJumbotron/VisitorJumbotron';
import i18nNamespaces from 'i18n/i18nNamespaces';
import getAPIErrorI18nKey from 'utils/getAPIErrorI18nKey';
import CTAButton from 'components/CTAButton/CTAButton';
import FormSection from 'components/FormSection/FormSection';

import './CreditCardPaymentForm.scss';
import useBeforeUnloadBrowserAlert from 'hooks/useBeforeUnloadBrowserAlert';
import { CreatePaymentInput } from 'models/CreatePaymentInput';
import { postStripePayment } from 'api';

const YEAR_SHORT = new Date().getFullYear().toString();
const MIN_YEAR_2ND_LAST_DIGIT = YEAR_SHORT.substring(2, 3);
const MIN_YEAR_LAST_DIGIT = YEAR_SHORT.substring(3, 4);

const CARD_TYPE_LOGO_MAP = {
  'american-express': amexLogo,
  'diners-club': dinersClubLogo,
  discover: discoverLogo,
  jcb: jcbLogo,
  mastercard: mastercardLogo,
  unionpay: unionpayLogo,
  visa: visaLogo,
};
type CardType = keyof typeof CARD_TYPE_LOGO_MAP;

const CARD_TYPES: CardType[] = ['visa', 'mastercard', 'american-express', 'discover', 'diners-club', 'jcb', 'unionpay'];

const CreditCardPaymentForm: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(i18nNamespaces.CREDIT_CARD_PAYMENT);
  const { t: tApp } = useTranslation(i18nNamespaces.APP);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [activeCardTypes, setActiveCardTypes] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    watch,
  } = useForm<CreatePaymentInput>();
  const { cardNo } = watch();

  const validateCreditCardNum: Validate<CreatePaymentInput['cardNo']> = (value) => {
    if (!isValidCreditCardNum(value)) return 'form_error_credit_card_no_invalid';
    return true;
  };

  const validateCreditCardSecurityCode: Validate<CreatePaymentInput['securityCode']> = (value) => {
    if (!isValidCreditCardSecurityCode(value, cardNo)) return 'form_error_credit_card_security_code_invalid';
    return true;
  };

  const highlightCreditCardTypes = (cardNumber: string) => {
    const matchedTypes = creditCardType(cardNumber?.replace(/\s+/g, '')?.trim());
    setActiveCardTypes(matchedTypes.map((card) => card.type));
  };

  useEffect(() => {
    highlightCreditCardTypes(cardNo);
  }, [cardNo]);

  useBeforeUnloadBrowserAlert(!isDirty);

  const onSubmit = async (data: CreatePaymentInput) => {
    const input: CreatePaymentInput = {
      ...data,
      cardNo: data.cardNo.replace(/\s+/g, ''),
      expiryDate: (() => {
        const dateStr = data.expiryDate.replace(/\s+/g, '');
        const [month, year] = dateStr.split('/');
        const yearPfx = new Date().getFullYear().toString().substring(0, 2);
        return `${zeroPadding(+month)}/${yearPfx}${year}`;
      })(),
      paymentAmount: Number(data.paymentAmount),
    };
    setSubmitError(null);
    try {
      const res = await postStripePayment(input);

      if (res.code === 200) {
        setTimeout(() => history.push('/payment/credit-card/success'), 0);
        return;
      }
      if (res.statusCode === 400) {
        setSubmitError(res.message ? t(`error_${res.message}`) : null);
        return;
      }
      throw new Error();
      return;
    } catch (err: any) {
      if (err.response?.status === 400) {
        const message = err.response?.data?.message;
        setSubmitError(message ? t(`error_${message}`) : null);
        return;
      }
      // @TODO: Log error message to server
      setSubmitError(t(getAPIErrorI18nKey(err, i18nNamespaces.CREDIT_CARD_PAYMENT)));
    }
  };

  const renderCardLogo = (type: CardType) => {
    const cardLogoSrc = CARD_TYPE_LOGO_MAP[type];
    const isInactive = activeCardTypes.length > 0 && !activeCardTypes.includes(type);
    return (
      <img
        key={type}
        src={cardLogoSrc}
        alt={`We accept: ${type}`}
        className={`CreditCardPaymentForm-credit-card-logos-item mod-${type} ${isInactive ? 'mod-inactive' : ''}`}
      />
    );
  };

  return (
    <VisitorLayout className="CreditCardPaymentForm" hasError={!!submitError}>
      {({ formClassName, formContainerClassName, formButtonsContainerClassName, formErrorClassName }) => (
        <>
          <VisitorJumbotron title={parseHTML(tApp('heading'))} description={parseHTML(tApp('subheading'))} />
          <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
            <div className={formContainerClassName}>
              <FormSection title={t('section_patient')}>
                <InputTextField
                  {...register('firstName', { required: true })}
                  name="firstName"
                  label={t('firstName')}
                  placeholder={t('firstName_placeholder')}
                  disabled={isSubmitting}
                  status={errors?.firstName ? 'error' : 'active'}
                  pattern="[a-zA-Z0-9\s]+"
                  minLength={2}
                  maxLength={50}
                />
                <InputTextField
                  {...register('lastName', { required: true })}
                  name="lastName"
                  label={t('lastName')}
                  placeholder={t('lastName_placeholder')}
                  disabled={isSubmitting}
                  status={errors?.lastName ? 'error' : 'active'}
                  pattern="[a-zA-Z0-9]+"
                  minLength={2}
                  maxLength={50}
                />
                <InputTextField
                  {...register('email', { required: true })}
                  name="email"
                  label={t('email')}
                  type="email"
                  placeholder={t('email_placeholder')}
                  disabled={isSubmitting}
                  status={errors?.email ? 'error' : 'active'}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
                <InputTextField
                  {...register('accountNo', { required: true })}
                  name="accountNo"
                  label={t('accountNo')}
                  placeholder={t('accountNo_placeholder')}
                  disabled={isSubmitting}
                  status={errors?.accountNo ? 'error' : 'active'}
                  pattern="[a-zA-Z0-9]+"
                  minLength={1}
                  maxLength={50}
                />
              </FormSection>
              <FormSection title={t('section_amount')}>
                <InputTextField
                  {...register('paymentAmount', { required: true })}
                  name="paymentAmount"
                  placeholder={t('amount_placeholder')}
                  type="number"
                  min={0.01}
                  max={9999999999}
                  step="0.01"
                  className="common-form-field-amount"
                  elementAfter={<div className="common-form-field-amount-prefix">USD $</div>}
                  disabled={isSubmitting}
                  status={errors?.paymentAmount ? 'error' : 'active'}
                  pattern="[0-9]+(\.[0-9]{1,2})?"
                />
              </FormSection>
              <FormSection title={t('section_card_info')}>
                <InputTextField
                  {...register('cardNo', { required: true, validate: validateCreditCardNum })}
                  name="cardNo"
                  label={t('cardNumber')}
                  placeholder="e.g. 4242 4242 4242 4242"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('cardNo', formatCreditCardNum(e.target.value));
                  }}
                  onBlur={(e) => setValue('cardNo', formatCreditCardNum(e.target.value))}
                  disabled={isSubmitting}
                  status={errors?.cardNo ? 'error' : 'active'}
                  bottomLabel={errors?.cardNo?.message && t(errors.cardNo.message)}
                />
                <InputTextField
                  {...register('expiryDate', { required: true })}
                  name="expiryDate"
                  label={t('cardExp')}
                  placeholder="MM/YY (e.g. 12/26)"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('expiryDate', formatCardExp(e.target.value));
                  }}
                  onBlur={(e) => setValue('expiryDate', formatCardExp(e.target.value))}
                  disabled={isSubmitting}
                  status={errors?.expiryDate ? 'error' : 'active'}
                  pattern={`((0[1-9])|(1[0-2]))\\s\\/\\s[${MIN_YEAR_2ND_LAST_DIGIT}-9][${MIN_YEAR_LAST_DIGIT}-9]`}
                />
                <InputTextField
                  {...register('securityCode', { required: true, validate: validateCreditCardSecurityCode })}
                  name="securityCode"
                  label={t('cardSecurityCode')}
                  placeholder="e.g. 123"
                  min={3}
                  max={3}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue('securityCode', formatCreditCardSecurityCode(e.target.value, cardNo));
                  }}
                  onBlur={(e) => setValue('securityCode', formatCreditCardSecurityCode(e.target.value, cardNo))}
                  disabled={isSubmitting}
                  status={errors?.securityCode ? 'error' : 'active'}
                  pattern="[0-9]{3,4}"
                  bottomLabel={errors?.securityCode?.message && t(errors.securityCode.message)}
                />
                <InputTextField
                  {...register('address', { required: true })}
                  name="address"
                  label={t('billingAddress')}
                  placeholder="e.g. 123 ABC Street, NY"
                  disabled={isSubmitting}
                  status={errors?.address ? 'error' : 'active'}
                  minLength={5}
                  maxLength={50}
                  pattern="[a-zA-Z0-9\s,\.\-']+"
                />
                <InputTextField
                  {...register('zipCode', { required: true })}
                  name="zipCode"
                  label={t('billingZipCode')}
                  placeholder="e.g. 10001"
                  disabled={isSubmitting}
                  status={errors?.zipCode ? 'error' : 'active'}
                  minLength={5}
                  maxLength={5}
                  // US zip code format
                  pattern="[0-9]{5}"
                />
              </FormSection>
            </div>
            <div>{CARD_TYPES.map(renderCardLogo)}</div>
            <br />
            <span className={formErrorClassName}>{`${submitError}`}</span>
            <div className={formButtonsContainerClassName}>
              <CTAButton type="submit" text={isSubmitting ? t('ctaLoading') : t('cta')} disabled={isSubmitting} />
            </div>
          </form>
          <br />
          <br />
          <br />
        </>
      )}
    </VisitorLayout>
  );
};

export default CreditCardPaymentForm;
