import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import './i18n';
import AppVersionCheckPrompt from 'components/AppVersionCheckPrompt';
import { store, persistor } from './store';
import i18nNamespaces from './i18n/i18nNamespaces';
import MainRouter from './router';

function App() {
  const { t } = useTranslation(i18nNamespaces.APP);

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Helmet>
            <title>{t('title')}</title>
          </Helmet>
          <MainRouter />
        </PersistGate>
      </Provider>
      <AppVersionCheckPrompt />
    </>
  );
}

export default App;
