import React, { memo } from 'react';
import Modal, { Styles, Props as ModalProps } from 'react-modal';
import useWindowSize from '../../hooks/useWindowSize';
import './ModalButtonList.scss';

export interface ButtonList {
  title: string;
  onClick: () => void;
  active?: true;
}
interface Props extends ModalProps {
  inActiveStyling?: true;
  list: ButtonList[];
}

export const ModalButtonList = memo<Props>(({ inActiveStyling, list, onRequestClose, ...restProps }) => {
  const { innerWidth } = useWindowSize();

  const modalStyle: Styles = {
    ...restProps.style,
    content: {
      width: Math.min(300, innerWidth - 40),
      height: 'fit-content',
      borderRadius: 20,
      margin: 'auto',
      left: 0,
      right: 0,
      padding: 0,
      ...restProps.style?.content,
    },
    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, ...restProps.style?.overlay },
  };

  return (
    <Modal {...restProps} style={modalStyle} onRequestClose={onRequestClose}>
      <ul className="ModalButtonList-list">
        {list.map((x) => (
          <li
            key={x.title}
            className={`ModalButtonList-list-item ${x.active ? 'mod-active' : ''} ${
              inActiveStyling ? 'mod-inActiveStyling' : ''
            }`}
          >
            <button
              type="button"
              className="ModalButtonList-list-item-button"
              onClick={(e) => {
                x.onClick();
                if (onRequestClose) onRequestClose(e);
              }}
            >
              {x.title}
            </button>
          </li>
        ))}
      </ul>
    </Modal>
  );
});

ModalButtonList.displayName = 'ModalButtonList';

export default ModalButtonList;
