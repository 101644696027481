import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nNamespaces from './i18nNamespaces';
import languages from './languages';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = Object.values(languages).reduce(
  (resObj, lng) => ({
    ...resObj,
    [lng]: Object.values(i18nNamespaces).reduce(
      (lngObj, namespace: string) => ({
        ...lngObj,
        // eslint-disable-next-line global-require, import/no-dynamic-require
        [namespace]: require(`./${lng}/${namespace}.json`) || {},
      }),
      {},
    ),
  }),
  {},
);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,

      lng: languages.EN, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      fallbackLng: languages.EN,

      defaultNS: i18nNamespaces.APP,

      interpolation: {
        escapeValue: false, // react already safes from xss
      },

      debug: process.env.NODE_ENV === 'development',
    },
    () => {
      // Bind i18n to global scope for debug use
      if (process.env.NODE_ENV === 'development') {
        // @ts-expect-error: Just for dev use
        window.i18n = i18n;
      }
    },
  );

export default i18n;
